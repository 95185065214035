<template>
  <button
    class="
      group
      text-sm
      flex-shrink-0
      rounded
      inline-flex
      gap-2
      p-2
      items-center
      transition-colors
      duration-150
      font-semibold
    "
    :class="{
      'text-primary': active || (checkActualRoute && $router.currentRoute.path == to),
      'opacity-60 cursor-not-allowed': disabled,
      'hover:text-primary': !disabled,
      'w-full': fullWidth,
    }"
    :disabled="disabled"
    @click="goToOrRun"
  >
    <span
      v-if="!noIcon"
      class="
        rounded-xl
        text-gray-500
        opacity-50
        w-7
        min-w-max
        flex-shrink-0
      "
      :class="{
        'text-primary': active,
        'group-hover:text-primary group-hover:opacity-90': !disabled,
      }"
    >
      <slot name="svg">
        <component :is="'fw-icon-' + icon" class="w-6 h-6" />
      </slot>
    </span>
    <span class="group-opacity-100 text-left" :class="{ 'text-sm': size === 'sm' }">
      <span class="line-clamp-1 whitespace-nowrap">{{ label }}</span>
    </span>
    <span v-if="counter" class="text-xs px-1.5 py-0.5 rounded-full bg-primary text-white flex-shrink-0">{{
      counter
    }}</span>
    <span v-if="$slots.suffix" class="flex-shrink-0"><slot name="suffix"></slot></span>
  </button>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'base',
    },
    icon: {
      type: String,
      default: null,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    checkActualRoute: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    counter: {
      type: String,
      default: null,
    },
  },

  methods: {
    goToOrRun() {
      if (this.to) this.$router.push(this.to)
    },
  },
}
</script>
