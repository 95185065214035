<template>
  <div
    class="rounded-2xl overflow-hidden relative bg-center bg-cover text-center px-8 pt-5 pb-8 text-white flex flex-col shadow-xl group"
    :style="{
      backgroundImage: 'url(' + backgroundImage + ')',
      'min-height': size == 'lg' ? '32rem' : size == 'sm' ? '24rem' : size == 'xs' ? '16rem' : '24rem',
    }"
  >
    <div v-if="!loading" style="z-index: 1" class="flex gap-5 justify-between py-2">
      <div v-if="tag || $slots['tag']">
        <slot name="tag">
          <div class="flex gap-2 items-center flex-shrink-0">
            <component :is="'fw-icon-' + tagIcon" v-if="tagIcon" class="w-7 h-7 opacity-60 flex-shrink-0" />
            <div class="font-semibold text-lg opacity-90">{{ tag }}</div>
          </div>
        </slot>
      </div>
      <div v-if="$slots['toolbar']">
        <slot name="toolbar" />
      </div>
    </div>
    <div
      v-if="!loading && (title || $slots['title'])"
      class="flex-1 items-center flex flex-col"
      style="z-index: 1"
      :class="{ 'justify-center': titlePosition == 'center', 'justify-end mb-10': titlePosition == 'end' }"
    >
      <fw-label v-if="titleLabel" color="text-white" class="opacity-50">{{ titleLabel }}</fw-label>
      <slot name="title">
        <fw-heading size="h1">
          <v-clamp autoresize :max-lines="3" class="max-w-3xl max-auto mb-3">{{ title }}</v-clamp>
        </fw-heading>
      </slot>
    </div>
    <div v-if="!loading && $slots['default']" style="z-index: 1">
      <slot name="default"></slot>
    </div>
    <div
      v-if="!loading && $slots['footer']"
      class="w-full flex flex-wrap gap-7 md:gap-10 justify-center text-white"
      style="z-index: 1"
    >
      <slot name="footer"></slot>
    </div>
    <div v-if="loading" class="flex flex-1 justify-center items-center text-white z-10">
      <fw-icon-loading />
    </div>
    <div
      class="bg-black h-full w-full top-0 left-0 absolute bg-opacity-40 group-hover:bg-opacity-50"
      style="z-index: 0"
      :class="{
        'backdrop-blur-xl': type == 'blur-xl',
        'backdrop-blur-sm': type == 'blur-sm',
        'as-cover': type == 'cover',
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'DashboardHero',
  props: {
    type: {
      type: String,
      default: 'blur-xl',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    titleLabel: {
      type: String,
      default: null,
    },
    titlePosition: {
      type: String,
      default: 'center',
    },
    tag: {
      type: String,
      default: null,
    },
    tagIcon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
    },
    backgroundImageUrl: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: 'abstract-01',
    },
  },
  computed: {
    backgroundImage() {
      if (this.backgroundImageUrl) return this.backgroundImageUrl
      if (!this.background) return null

      let bkimgMap = {
        'abstract-01': 'https://static.fw.uc.pt/banners/abstract-01.jpg',
        'abstract-02': 'https://static.fw.uc.pt/banners/abstract-02.jpg',
        'abstract-03': 'https://static.fw.uc.pt/banners/abstract-03.jpg',
      }

      if (bkimgMap.hasOwnProperty('key')) {
        return bkimgMap[this.background]
      }

      return `https://static.fw.uc.pt/banners/${this.background}.jpg`
    },
  },
}
</script>

<style>
.as-cover {
  background: rgb(50, 68, 87);
  background: linear-gradient(0deg, rgba(50, 68, 87, 0.95) 25%, rgba(50, 68, 87, 0.35) 85%);
}
</style>
