var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-2xl overflow-hidden relative bg-center bg-cover text-center px-8 pt-5 pb-8 text-white flex flex-col shadow-xl group",style:({
    backgroundImage: 'url(' + _vm.backgroundImage + ')',
    'min-height': _vm.size == 'lg' ? '32rem' : _vm.size == 'sm' ? '24rem' : _vm.size == 'xs' ? '16rem' : '24rem',
  })},[(!_vm.loading)?_c('div',{staticClass:"flex gap-5 justify-between py-2",staticStyle:{"z-index":"1"}},[(_vm.tag || _vm.$slots['tag'])?_c('div',[_vm._t("tag",function(){return [_c('div',{staticClass:"flex gap-2 items-center flex-shrink-0"},[(_vm.tagIcon)?_c('fw-icon-' + _vm.tagIcon,{tag:"component",staticClass:"w-7 h-7 opacity-60 flex-shrink-0"}):_vm._e(),_c('div',{staticClass:"font-semibold text-lg opacity-90"},[_vm._v(_vm._s(_vm.tag))])],1)]})],2):_vm._e(),(_vm.$slots['toolbar'])?_c('div',[_vm._t("toolbar")],2):_vm._e()]):_vm._e(),(!_vm.loading && (_vm.title || _vm.$slots['title']))?_c('div',{staticClass:"flex-1 items-center flex flex-col",class:{ 'justify-center': _vm.titlePosition == 'center', 'justify-end mb-10': _vm.titlePosition == 'end' },staticStyle:{"z-index":"1"}},[(_vm.titleLabel)?_c('fw-label',{staticClass:"opacity-50",attrs:{"color":"text-white"}},[_vm._v(_vm._s(_vm.titleLabel))]):_vm._e(),_vm._t("title",function(){return [_c('fw-heading',{attrs:{"size":"h1"}},[_c('v-clamp',{staticClass:"max-w-3xl max-auto mb-3",attrs:{"autoresize":"","max-lines":3}},[_vm._v(_vm._s(_vm.title))])],1)]})],2):_vm._e(),(!_vm.loading && _vm.$slots['default'])?_c('div',{staticStyle:{"z-index":"1"}},[_vm._t("default")],2):_vm._e(),(!_vm.loading && _vm.$slots['footer'])?_c('div',{staticClass:"w-full flex flex-wrap gap-7 md:gap-10 justify-center text-white",staticStyle:{"z-index":"1"}},[_vm._t("footer")],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flex flex-1 justify-center items-center text-white z-10"},[_c('fw-icon-loading')],1):_vm._e(),_c('div',{staticClass:"bg-black h-full w-full top-0 left-0 absolute bg-opacity-40 group-hover:bg-opacity-50",class:{
      'backdrop-blur-xl': _vm.type == 'blur-xl',
      'backdrop-blur-sm': _vm.type == 'blur-sm',
      'as-cover': _vm.type == 'cover',
    },staticStyle:{"z-index":"0"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }