<template>
  <button
    class="text-left relative rounded-lg px-3 py-1.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex gap-5 items-center"
    @click="$emit('open', team.key)"
  >
    <div>
      <TeamAvatarManage :size="size" :team="team" />
    </div>
    <div class="flex-1 text-left">
      <v-clamp v-if="team.name && team.name.length > 0" autoresize :max-lines="1" class="font-medium">
        {{ team.name }}
      </v-clamp>
      <span v-else class="text-gray-200">Sem título</span>
    </div>

    <div v-if="team.stage" class="w-20 text-xs">
      {{ stages[team.stage][language] }}
    </div>

    <div v-if="showTeamGroup && teamGroup?.key" class="w-1/4 truncate text-sm">
      {{ teamGroup?.name }}
    </div>

    <div class="text-xs text-gray-500 flex gap-2 items-center">
      <fw-dot :color="team.has_updates ? 'primary' : 'light'" inline :ping="team.has_updates" />
      <span>{{ team.updated_at | formatDateTime }}</span>
    </div>

    <div v-if="showStatus">
      <fw-tag :type="status[team.status].color" class="ml-auto">
        <v-clamp autoresize :max-lines="1" class="w-24">
          {{ status[team.status].label[language] }}
        </v-clamp>
      </fw-tag>
    </div>
    <div class="w-8">
      <div v-if="user" class="relative">
        <fw-avatar size="xs" :user="user" />
        <fw-badge v-if="team.players.length > 1" class="-mr-1">+{{ team.players.length - 1 }}</fw-badge>
      </div>
      <div v-else class="flex items-center justify-center">
        <fw-icon-user-unfollow class="text-gray-300 w-8 h-8 bg-gray-50 rounded-lg p-1" />
      </div>
    </div>
  </button>
</template>

<script>
import { TEAM_STATES, TOURNAMENT_STAGES } from '@/utils/index.js'
import TeamAvatarManage from '@/components/TeamAvatarManage'

export default {
  components: {
    TeamAvatarManage,
  },
  props: {
    team: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    teamGroup: {
      type: Object,
      default: () => {},
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    showTeamGroup: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },

  data() {
    return {
      status: TEAM_STATES,
      stages: TOURNAMENT_STAGES,
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },
  },
}
</script>
