<template>
  <div>
    <PanelCreateTeam
      :team="team"
      :ask-group-key="askGroupKey"
      :tmp-team-member="tmpTeamMember"
      :team-errors="$v.team"
      :verifying-user="verifyingUser"
      :can-save-team="canSaveTeam"
      :saving="saving"
      :minimum-team-members-error="minimumTeamMembersError"
      :show-submit-button="false"
      :boxed="false"
      :management="true"
      :logo="logo"
      @update-team-image="updateTeamImage(uploadedFiles)"
      @cancel="cancelTeam"
      @remove-logo="removeLogo"
      @save-team="saveTeam(false, false, $event)"
      @select-color="selectColor"
      @select-icon="selectIcon"
      @set-leader="setLeader"
      @remove-player="removeTeamMember"
      @add-player="addTeamMember"
    ></PanelCreateTeam>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="closeModal()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disable="saving" :saving="saving" class="w-28" @click.native="saveTeam(true, true)">
        {{ 'Criar' }}
      </fw-button>
    </div>
  </div>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import PanelCreateTeam from '@/components/panels/PanelCreateTeam'

export default {
  name: 'ModalCreateTeam',
  components: {
    PanelCreateTeam,
  },
  props: {
    editionKey: {
      type: String,
      required: true,
    },
    leagueKey: {
      type: String,
      required: true,
    },
    tournament: {
      type: Object,
    },
  },
  data() {
    return {
      uploadFiles: [],
      saving: false,
      tmpTeamMember: '',
      verifyingUser: false,
      team: {
        key: null,
        tournament_key: null,
        name: null,
        logo: null,
        leader: null,
        color: null,
        status: 'draft',
        players: [],
        submitted_at: null,
        confirmed_at: null,
        denied_at: null,
        deleted_at: null,
      },
      logo: null,
      teamGroups: [],
      errors: [],
    }
  },
  validations: {
    team: {
      name: { required, min: minLength(1) },
      players: { required, min: minLength(1) },
    },
  },
  computed: {
    tournamentKey() {
      return this.tournament.key
    },
    user() {
      return this.$store.getters.getUser
    },
    api() {
      return this.$store.state.api.base
    },
    canSaveTeam() {
      return this.team.name != '' && this.team.players.length > 0
    },
    // Checks
    isRunning() {
      return this.tournament.status == 'running'
    },
    isOpenToApplications() {
      return this.tournament.status == 'signup-open'
    },
    isClosedToApplications() {
      return this.tournament.status == 'signup-closed'
    },
    sportName() {
      if (this.tournament.sport.category != null) {
        return this.tournament.sport.modality.title + ' - ' + this.tournament.sport.category
      }
      return this.tournament.sport.modality.title
    },
    birthdayError() {
      let birthdayErrors = this.errors.filter(e => e.field === 'birthday')
      if (birthdayErrors.length > 0) {
        switch (birthdayErrors[0].key) {
          case 'MaxDate':
            return (
              'A data de nascimento tem de ser inferior a ' +
              this.$options.filters.formatDate(birthdayErrors[0].max_date)
            )
          default:
            return null
        }
      } else {
        return null
      }
    },
    minimumTeamMembersError() {
      let playerErrors = this.errors.filter(e => e.field === 'players')
      if (playerErrors.length > 0) {
        switch (playerErrors[0].key) {
          case 'MinNumber':
            return 'Uma equipa tem de ter pelo menos ' + (playerErrors[0].min_number + 1) + ' elementos'
          case 'MaxNumber':
            return 'Uma equipa só pode ter no máximo ' + playerErrors[0].max_number + ' elementos'
          default:
            return null
        }
      } else {
        return null
      }
    },
    askGroupKey() {
      return !this.tournament.league.open_signup ?? false
    },
    maxNumberTeamMembers() {
      return this.tournament.sport.max_players ?? 0
    },
    teamKey() {
      return this.$route.params.teamKey
    },
  },
  mounted() {
    this.getTeamGroups()
  },
  methods: {
    cancelTeam() {
      this.$buefy.dialog.confirm({
        confirmText: 'Cancelar inscrição',
        type: 'is-danger',
        cancelText: 'Sair',
        title: `Cancelar inscrição da equipa`,
        message: `Tem a certeza que deseja cancelar a inscrição da sua equipa?`,
        onConfirm: () => {
          this.cancelTeamRequest()
        },
      })
    },
    async cancelTeamRequest() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.cancelTeamSignUp(this.tournamentKey, this.teamKey)
        console.log('cancelTeamRequest :>> ', response)
        this.$router.push({ name: 'home' })
      })
    },
    setLeader(leader) {
      this.team.leader = leader
      this.saveTeam(false, false)
    },

    removeLogo() {
      this.logo = null
      this.team.logo_key = null
    },
    selectIcon(icon) {
      this.team.icon_key = icon
      this.removeLogo()
      this.saveTeam(false, false)
    },
    selectColor(color) {
      this.team.color = color
      this.removeLogo()
      this.saveTeam(false, false)
    },
    removeTeamMember(i) {
      this.team.players.splice(i, 1)
      this.verifyTeamNumber()
    },
    verifyTeamNumber() {
      if (this.team.players.length < this.tournament.sport.min_players) {
        // if error not already present
        if (!this.errors.find(e => e.field === 'players' && e.key === 'MinNumber')) {
          this.errors.push({
            field: 'players',
            key: 'MinNumber',
            min_number: this.tournament.sport.min_players,
          })
        }
      } // if max number players exceeded
      else if (this.team.players.length > this.tournament.sport.max_players) {
        // if error not already present
        if (!this.errors.find(e => e.field === 'players' && e.key === 'MaxNumber')) {
          this.errors.push({
            field: 'players',
            key: 'MaxNumber',
            max_number: this.tournament.sport.max_players,
          })
        }
      }
    },
    addTeamMember(teamMember) {
      this.team.players.push(teamMember)
      this.saveTeam(false, false)
    },
    async updateTeamImage(uploadedFiles) {
      var file = uploadedFiles[0]
      this.team.logo_key = file.response.data.file.key
      console.log('updateTeamImage', file.response.data.file.key)
      this.saveTeam(false, false)
    },

    async saveTeam(validate = true, submit = false, teamData) {
      if (this.saving) return
      if (validate) {
        this.errors = []
        this.$v.team.$touch()
        if (this.$v.team.$error) return
      }
      try {
        this.saving = true
        let data = teamData
        if (submit) {
          data['submit'] = true
        }
        const response = this.team.key
          ? await this.api.updateTeam(this.tournamentKey, this.team.key, data)
          : await this.api.createTeam(this.tournamentKey, data)
        console.log('saveTeam :>> ', response)
        if (response.key) {
          this.team.key = response.key
        }
        this.logo = response.logo

        if (submit) {
          this.$emit('saved')
        }
      } catch (error) {
        console.log('Error saveTeam :>> ', error)
        let errorData = error.response.data['__errors__']
        let errorMessage = 'Ocorreu um erro ao inscrever a equipa.'
        if (errorData.length > 0) {
          if (errorData[0].key == 'NotFound') {
            errorMessage = 'O torneio não foi encontrado.'
          } else if (errorData[0].key == 'InvalidOrganicUnit') {
            errorMessage = 'A unidade orgânica selecionada não é válida.'
          } else if (errorData[0].key == 'InvalidTournamentStage') {
            errorMessage = 'O torneio não se encontra na fase de inscrições.'
          } else if (errorData[0].key == 'SignupNotAvailable') {
            errorMessage = 'As inscrições para o torneio não estão disponíveis.'
          } else if (errorData[0].key == 'TeamNameAlreadyExists') {
            errorMessage = 'Já existe uma equipa com esse nome.'
          } else if (errorData[0].key == 'Forbidden') {
            errorMessage = 'Não tem permissões para criar equipas para esta unidade orgânica.'
          } else if (errorData[0].key == 'MinNumber') {
            errorMessage = 'Uma equipa tem de ter pelo menos ' + (errorData[0].min_number + 1) + ' jogadores.'
          }
        }
        this.$buefy.snackbar.open({
          message: errorMessage,
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
        this.errors = errorData
      } finally {
        this.saving = false
      }
    },
    async getTeamGroups() {
      try {
        const response = await this.api.getTeamGroups({
          sort: 'name',
          direction: 'asc',
          with_org_units: true,
        })
        console.log('getTeamGroups :>> ', response)
        this.teamGroups = response.data
      } catch (error) {
        console.log('Error getTeamGroups :>> ', error)
      }
    },
  },
}
</script>
