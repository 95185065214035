<template>
  <div class="flex flex-wrap gap-2">
    <button
      v-if="multiple"
      class="filter-tag"
      :class="{ active: !selectedOptions?.length }"
      @click="selectOption('all')"
    >
      Todos
    </button>

    <button
      v-for="(label, key) in options"
      :key="key"
      class="filter-tag"
      :class="{ active: selectedOptions?.includes(key) }"
      @click="selectOption(key)"
    >
      {{ label[language] ?? label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return { myKey: 'Value' }
      },
    },
    selectedOptions: {
      type: [Array, String, Number],
      default: 'all',
    },
  },

  data() {
    return {
      selected: 'all',
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
  },

  mounted() {
    this.selected = this.selectedOptions
    if (!this.multiple) this.selected = null
  },

  methods: {
    selectOption(value) {
      if (this.multiple) {
        const hasItems = this.selected?.length > 0
        const hasNewVal = this.selected?.includes(value)

        if (value == 'all' && hasItems) {
          this.selected = []
        } else if (hasNewVal) {
          const newVal = this.selected.filter(el => value != el)

          this.selected = newVal
        } else {
          this.selected = this.selected.concat([value])
        }
      } else {
        this.selected = value
      }

      this.$emit('selected', this.selected)
      this.$emit('update:selectedOptions', this.selected) // To update the prop if using :selected-options.sync
    },
  },
}
</script>

<style lang="postcss">
.filter-tag {
  @apply px-2.5 select-none py-1 flex items-center cursor-pointer bg-gray-100 text-gray-600 rounded-lg text-sm;
}
.filter-tag.active {
  @apply bg-primary text-white font-medium;
}
</style>
