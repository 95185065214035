<template>
  <section>
    <ContextualSearch
      v-if="showSearch"
      :loading="loading"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :multifilter="multifilter"
      :start-value="searchInput"
      :start-period="startPeriod"
      :end-period="endPeriod"
      :order-by-options="orderBy"
      :show-time-period="false"
      :time-period-label="$t('startDate')"
      :can-close="activeModal === null"
      :show-filters="showFilterLabels"
      @sort-order-changed="sortOrderChanged"
      @search="search"
    >
      <template #tags>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod != null && endPeriod != null"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="teams ? teams.length : 0"
      :counter-total="totalResults"
      boxed
      class="my-5"
      custom-class="bg-white"
    >
      <fw-panel-info v-if="!loading && !teams.length" type="basic">
        {{ $t('noTeams') }}
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />
      <div v-else>
        <div v-for="team in teams" :key="team.key" class="p-1 border-b border-gray-100">
          <RecordManageTeam
            :team="team"
            :user="users[team.leader]"
            :team-group="teamGroups[team.group_key]"
            :show-team-group="!!tournamentKey"
            size="xs"
            @open="goToTeam"
          />
        </div>
      </div>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />
  </section>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordManageTeam from '@/components/records/manage/RecordManageTeam'

import { TEAM_STATES } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  name: 'PanelManageTeams',
  components: {
    ContextualSearch,
    FilterTag,
    BlockPagination,
    LoadingPlaceholder,
    RecordManageTeam,
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    isAcademic: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: true,
    },
    maxNumberSpaces: {
      type: Number,
      default: null,
    },
    editionKey: {
      type: String,
      default: null,
    },
    leagueKey: {
      type: String,
      default: null,
    },
    tournamentKey: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      filterByType: 'all',
      teamstatus: TEAM_STATES,
      filters: [
        {
          key: 'status',
          label: this.$t('teamstatus'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
          ],
        },
      ],
      orderBy: [
        {
          key: 'name',
          label: this.$t('orderBy.title'),
          type: 'string',
        },
        {
          key: 'created_at',
          label: this.$t('orderBy.startDate'),
          type: 'string',
        },
      ],
      allTeamGroups: [],
      searchInput: '',
      orderByValue: 'created_at',
      orderDirection: 'DESC',
      appliedFilters: ['status:draft_2', 'status:submitted', 'status:approved', 'status:denied', 'status:cancelled'],
      startPeriod: null,
      endPeriod: null,
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      teams: [],
      users: {},
      teamGroups: {},
      loading: true,
      activeModal: null,
      filterLabels: {},
      showFilterLabels: true,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },
  },

  watch: {
    filterLabels() {
      //Hackish way to force rerender of filter labels, improve later
      this.rerenderFilterLabels++
    },
  },

  mounted() {
    this.filters = [
      {
        key: 'status',
        label: this.$t('teamstatus'),
        options: [
          {
            key: 'reset',
            label: this.$t('all'),
          },
          {
            key: 'draft_1',
            label: this.teamstatus.draft_1.label[this.language],
          },
          {
            key: 'draft_2',
            label: this.teamstatus.draft_2.label[this.language],
          },
          {
            key: 'submitted',
            label: this.teamstatus.submitted.label[this.language],
          },
          {
            key: 'approved',
            label: this.teamstatus.approved.label[this.language],
          },
          {
            key: 'denied',
            label: this.teamstatus.denied.label[this.language],
          },
          {
            key: 'cancelled',
            label: this.teamstatus.cancelled.label[this.language],
          },
        ],
      },
    ]
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getTeams()

      if (this.isAcademic) this.getTeamGroups()
    })
  },

  methods: {
    async getTeamGroups() {
      try {
        const response = await this.api.getTeamGroups({
          sort: 'name',
          direction: 'asc',
        })
        console.log('getTeamGroups :>> ', response)
        this.allTeamGroups = response.groups
        // IMPROVE THIS
        this.filters.push({
          key: 'team_group_keys',
          label: 'Núcleos',
          options: this.allTeamGroups.map(el => {
            return {
              key: el.key,
              label: el.name,
            }
          }),
        })
      } catch (error) {
        console.log('Error getTeamGroups :>> ', error)
      }
    },

    refresh() {
      //reset and reload teams
      this.page = 1
      this.filterByType = 'all'
      this.searchInput = ''
      this.orderByValue = 'created_at'
      this.orderDirection = 'DESC'
      this.appliedFilters = [
        'status:draft_2',
        'status:draft_1',
        'status:submitted',
        'status:approved',
        'status:denied',
        'status:cancelled',
      ]
      this.startPeriod = null
      this.endPeriod = null
      this.getTeams()
    },

    goToTeam(teamKey) {
      let routeName = 'manage-edition-league-team'
      let routeParams = {
        editionKey: this.editionKey,
        leagueKey: this.leagueKey,
        teamKey: teamKey,
      }

      const query = {}
      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.tournamentKey) {
        routeName = 'manage-edition-league-tournament-team'
        routeParams.tournamentKey = this.tournamentKey
      }

      this.$router.push({
        name: routeName,
        params: routeParams,
        query: query,
      })
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getTeams()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getTeams()
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      console.log('search', data)
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getTeams()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getTeams()
    },

    async getTeams() {
      this.loading = true
      const maxNumber = this.maxNumberSpaces != null ? this.maxNumberSpaces : this.limit
      const query = { limit: maxNumber, page: this.page, ...utils.setFiltersQuery(this.appliedFilters) }

      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      try {
        let result = await this.api.managementGetTeams(this.editionKey, this.leagueKey, this.tournamentKey, query)
        console.log('managementGetTeams :>> ', result)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.teams = result.teams
        this.users = result.users
        this.teamGroups = result.team_groups
      } catch (error) {
        console.error('getteams Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getTeams()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "teamstatus": "Estados",
    "startDate": "Data de início",
    "results": "Resultados",
    "loadMoreData": "Ver mais resultados",
    "noTeams": "Sem equipas para apresentar.",
    "all": "Todos",
    "organicUnits": "Núcleos de estudantes",
    "orderBy": {
      "shortName": "Nome do espaço",
      "spaceStatus": "Estado do espaço",
      "startDate": "Data de início",
      "spaceType": "Tipo de espaço",
      "title": "Titulo do torneio",
      "prefix": "Prefixo do espaço",
      "endDate": "Data de fim",
      "startDate": "Data de início"
    }
  },
  "en": {
    "teamstatus": "Estados",
    "startDate": "Start date",
    "results": "Results",
    "noTeams": "No teams to present.",
    "loadMoreData": "Load more data",
    "organicUnits": "Organic Units",
    "all": "All",
    "orderBy": {
      "shortName": "Space short name",
      "spaceStatus": "Space status",
      "startDate": "Start date",
      "spaceType": "Space type",
      "title": "Title",
      "prefix": "Space prefix",
      "endDate": "End date",
      "startDate": "Start date"
    }
  }
}
</i18n>
