var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.stage)?_c('div',{staticClass:"w-full gap-4 flex rounded-xl p-2 border-b border-gray-100 text-sm items-center"},[_c('div',{staticClass:"w-40 text-gray-500"},[_c('p',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.typesList[_vm.stage.type]?.[_vm.language] ?? _vm.$t('notDefined')))]),(_vm.stage.max_teams)?_c('p',{staticClass:"font-medium"},[_vm._v("Máximo de "+_vm._s(_vm.stage.max_teams)+" equipas")]):_vm._e()]),_c('div',{staticClass:"w-20 text-gray-500 font-semibold"},[_vm._v(" "+_vm._s(_vm.organicUnit?.name || '')+" ")]),_c('div',{staticClass:"flex-1 text-gray-500 font-semibold"},[_vm._v(" "+_vm._s(_vm.teamGroup?.name || '')+" ")]),_c('div',{staticClass:"text-xs text-gray-500"},[(_vm.stage.teams?.length)?_c('span',[_vm._v(_vm._s(_vm.stage.teams?.length)+" equipa(s)")]):_vm._e()]),(_vm.stage.started_at)?_c('div',{staticClass:"text-center w-28 text-xs text-gray-500"},[_c('span',[_vm._v("Iniciada em "+_vm._s(_vm._f("formatDateTime")(_vm.stage.started_at)))])]):_vm._e(),(_vm.stage.ended_at)?_c('div',{staticClass:"text-center w-28 text-xs text-gray-500"},[_c('span',[_vm._v("Terminada em "+_vm._s(_vm._f("formatDateTime")(_vm.stage.ended_at)))])]):_vm._e(),_c('div',{staticClass:"flex gap-2 text-gray-200 font-semibold text-xs items-center"},[_c('PrizeIcon',{staticClass:"h-4 w-4",class:{ 'juc-text-color': !!_vm.stage?.winner_team }}),(!_vm.stage?.winner_team)?_c('UnkownTeamIcon',{staticClass:"h-6 w-6"}):(_vm.stage?.winner_team)?_c('a',{attrs:{"href":_vm.$router.resolve({
          name: 'manage-edition-league-tournament-team',
          params: {
            editionKey: _vm.editionKey,
            leagueKey: _vm.leagueKey,
            tournamentKey: _vm.tournamentKey,
            teamKey: _vm.stage?.winner_team?.key,
          },
        }).href,"target":"_black"}},[_c('TeamAvatar',{attrs:{"team":_vm.stage?.winner_team,"size":"xs"}})],1):_vm._e()],1),(_vm.stage.challonge.url)?_c('a',{staticClass:"text-primary text-center rounded-lg font-bold transition-colors duration-150 border border-transparent focus:outline-none",attrs:{"href":_vm.stage.challonge.url,"target":"_black"}},[_vm._v(" Ver"),_c('br'),_vm._v("Challonge ")]):_vm._e(),(_vm.stage.validations?.can_edit || _vm.stage.validations?.can_end || _vm.canStart)?_c('b-dropdown',{staticClass:"ml-auto",attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('fw-button-dropdown',{staticClass:"flex flex-col",attrs:{"slot":"trigger","chevron":false,"type":"simple","label":"Ações"},slot:"trigger"},[_c('fw-icon-more',{staticClass:"w-5 h-5"})],1),_c('b-dropdown-item',{staticClass:"paddingless",attrs:{"custom":"","aria-role":"menuitem"}},[(_vm.stage.validations?.can_edit)?_c('fw-button',{attrs:{"type":"basic-action","size":"sm","label":"Editar","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.$emit('edit', _vm.stage)}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e(),(_vm.canStart)?_c('fw-button',{attrs:{"type":"basic-action","size":"sm","label":"Iniciar","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.$emit('start', _vm.stage)}}},[_vm._v(" "+_vm._s(_vm.$t('start'))+" ")]):(_vm.stage.validations?.can_end)?_c('fw-button',{attrs:{"type":"basic-action","size":"sm","label":"Terminar","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.$emit('end', _vm.stage)}}},[_vm._v(" "+_vm._s(_vm.$t('end'))+" ")]):_vm._e()],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }