<template>
  <div v-if="stage" class="w-full gap-4  flex rounded-xl p-2 border-b border-gray-100 text-sm items-center">
    <div class="w-40 text-gray-500">
      <p class="font-semibold">{{ typesList[stage.type]?.[language] ?? $t('notDefined') }}</p>
      <p v-if="stage.max_teams" class="font-medium">Máximo de {{ stage.max_teams }} equipas</p>
    </div>

    <div class="w-20 text-gray-500 font-semibold">
      {{ organicUnit?.name || '' }}
    </div>

    <div class="flex-1 text-gray-500 font-semibold">
      {{ teamGroup?.name || '' }}
    </div>

    <div class="text-xs text-gray-500">
      <span v-if="stage.teams?.length">{{ stage.teams?.length }} equipa(s)</span>
    </div>

    <div v-if="stage.started_at" class="text-center w-28 text-xs text-gray-500">
      <span>Iniciada em {{ stage.started_at | formatDateTime }}</span>
    </div>

    <div v-if="stage.ended_at" class="text-center w-28 text-xs text-gray-500">
      <span>Terminada em {{ stage.ended_at | formatDateTime }}</span>
    </div>

    <div class="flex gap-2 text-gray-200 font-semibold text-xs items-center">
      <PrizeIcon class="h-4 w-4" :class="{ 'juc-text-color': !!stage?.winner_team }"></PrizeIcon>
      <UnkownTeamIcon v-if="!stage?.winner_team" class="h-6 w-6"></UnkownTeamIcon>
      <a
        v-else-if="stage?.winner_team"
        :href="
          $router.resolve({
            name: 'manage-edition-league-tournament-team',
            params: {
              editionKey: editionKey,
              leagueKey: leagueKey,
              tournamentKey: tournamentKey,
              teamKey: stage?.winner_team?.key,
            },
          }).href
        "
        target="_black"
        ><TeamAvatar :team="stage?.winner_team" size="xs"></TeamAvatar
      ></a>
    </div>

    <a
      v-if="stage.challonge.url"
      :href="stage.challonge.url"
      class="text-primary text-center rounded-lg font-bold transition-colors duration-150 border border-transparent focus:outline-none"
      target="_black"
    >
      Ver<br />Challonge
    </a>

    <b-dropdown
      v-if="stage.validations?.can_edit || stage.validations?.can_end || canStart"
      aria-role="list"
      position="is-bottom-left"
      class="ml-auto"
    >
      <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
        <fw-icon-more class="w-5 h-5" />
      </fw-button-dropdown>
      <b-dropdown-item custom aria-role="menuitem" class="paddingless">
        <fw-button
          v-if="stage.validations?.can_edit"
          type="basic-action"
          size="sm"
          label="Editar"
          custom-class="w-full"
          @click.native="$emit('edit', stage)"
        >
          {{ $t('edit') }}
        </fw-button>

        <fw-button
          v-if="canStart"
          type="basic-action"
          size="sm"
          label="Iniciar"
          custom-class="w-full"
          @click.native="$emit('start', stage)"
        >
          {{ $t('start') }}
        </fw-button>

        <fw-button
          v-else-if="stage.validations?.can_end"
          type="basic-action"
          size="sm"
          label="Terminar"
          custom-class="w-full"
          @click.native="$emit('end', stage)"
        >
          {{ $t('end') }}
        </fw-button>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { GENDERS, TOURNAMENT_TYPES, TOURNAMENT_STAGES } from '@/utils/index.js'
import PrizeIcon from '@/components/icons/PrizeIcon.vue'
import UnkownTeamIcon from '@/components/icons/UnkownTeamIcon.vue'
import TeamAvatar from '@/components/TeamAvatar.vue'

export default {
  components: {
    PrizeIcon,
    UnkownTeamIcon,
    TeamAvatar,
  },

  props: {
    stage: {
      type: Object,
      default: () => {},
    },

    teamGroup: {
      type: Object,
      default: () => {},
    },

    organicUnit: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      stages: TOURNAMENT_STAGES,
      gendersList: GENDERS,
      typesList: TOURNAMENT_TYPES,
    }
  },

  computed: {
    editionKey() {
      return this.$route.params.editionKey
    },

    leagueKey() {
      return this.$route.params.leagueKey
    },

    tournamentKey() {
      return this.$route.params.tournamentKey
    },

    language() {
      return this.$store.state.language
    },

    canStart() {
      return this.stage.validations?.can_start && this.stage.teams?.length && !this.started_at
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notDefined": "Não definido",
    "edit": "Editar",
    "start": "Iniciar",
    "end": "Terminar"
  },
  "en": {
    "notDefined": "Not defined",
    "edit": "Edit",
    "start": "Start",
    "end": "End"
  }
}
</i18n>
