<template>
  <PanelNotifications
    :active-filters="activeFilters"
    :inject-payload="{ tournamentKey: tournamentKey }"
    :show-export-toolbar="false"
  />
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  components: {
    PanelNotifications,
  },

  props: {
    tournamentKey: {
      type: String,
    },
  },

  data() {
    return {
      activeFilters: {
        tournament: this.tournamentKey,
      },
    }
  },
}
</script>
