<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" :label="$t('actions')">
      <fw-icon-actions class="w-5 h-5" :class="{ 'text-primary animate-pulse': activeActionsCounter }" />
      <span :class="{ 'text-primary': activeActionsCounter }">{{ $t('actions') }}</span>
      <fw-badge v-if="activeActionsCounter">{{ activeActionsCounter }}</fw-badge>
    </fw-button-dropdown>
    <b-dropdown-item
      v-if="!activeActionsCounter && !activeActionsHiddenFromCounter"
      custom
      class="paddingless w-52 text-gray-500"
    >
      {{ $t('noActions') }}
    </b-dropdown-item>

    <div v-for="(item, i) in items" :key="i">
      <b-dropdown-item v-if="item.canShowAction" ref="actionitem" custom class="paddingless" aria-role="menuitem">
        <fw-button
          type="basic-action"
          expanded
          class="w-full my-0.5 text-left"
          :custom-class="item.customClass"
          @click.native="$emit(item.actionName)"
          >{{ item.actionLabel }}</fw-button
        >
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    activeActionsCounter() {
      let counter = 0
      for (const action of this.items) {
        if (action.addToCounter && action.canShowAction) counter += 1
      }
      return counter
    },
    activeActionsHiddenFromCounter() {
      let counter = 0
      for (const action of this.items) {
        if (!action.addToCounter && action.canShowAction) counter += 1
      }
      return counter
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "actions": "Ações",
    "noActions": "Não existem ações disponíveis neste momento."
  },
  "en": {
    "actions": "Actions",
    "noActions": "No actions available at this time."
  }
}
</i18n>
