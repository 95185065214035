<template>
  <SidebarBase sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="!view || view == 'dashboard'"
          :label="$t('dashboard')"
          icon="dashboard"
          @click.native="goToView('dashboard')"
        />
        <ButtonSidebar :active="view == 'teams'" :label="$t('teams')" icon="team" @click.native="goToView('teams')" />

        <ButtonSidebar
          v-if="userPermissions.isAnyManager"
          :active="isStageView"
          label="Fases"
          @click.native="goToView(verticalSteps[0].value)"
        >
          <template #svg>
            <fw-icon-cube class="w-6 h-6" />
          </template>
          <template #suffix>
            <fw-icon-chevron-up v-if="isStageView" class="w-4 h-4" />
            <fw-icon-chevron-down v-else class="w-4 h-4" />
          </template>
        </ButtonSidebar>

        <VerticalSteps
          v-if="isStageView"
          :steps="verticalSteps"
          :current-step="view"
          class="mb-2"
          :truncate-text="true"
          @clicked="goToView"
        />

        <ButtonSidebar
          v-if="userPermissions.isAnyManager"
          :active="view == 'settings'"
          :label="$t('settings')"
          icon="settings"
          @click.native="goToView('settings')"
        />

        <ButtonSidebar
          v-if="userPermissions.isGlobalManager || userPermissions.isLeagueManager"
          :label="$t('activity')"
          :active="view == 'activity'"
          icon="activity"
          @click.native="goToView('activity')"
        />
        <ButtonSidebar
          v-if="userPermissions.isGlobalManager || userPermissions.isLeagueManager"
          :label="$t('notifications')"
          :active="view == 'notifications'"
          icon="message-sent"
          @click.native="goToView('notifications')"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

import { TOURNAMENT_STAGES } from '@/utils/index.js'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps,
  },

  props: {
    hasOnlyFinalStage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stages: TOURNAMENT_STAGES,
    }
  },
  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },

    tournamentKey() {
      return this.$route.params.key
    },

    view() {
      return this.$route.params.view ?? 'dashboard'
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    isOnlyTeamGroupManager() {
      return (
        this.userPermissions.isTeamGroupManager &&
        !this.userPermissions.isGlobalManager &&
        !this.userPermissions.isLeagueManager
      )
    },

    isStageView() {
      return Object.keys(this.stages).includes(this.view)
    },

    verticalSteps() {
      const stages = []

      if (!this.hasOnlyFinalStage) {
        for (const [key, label] of Object.entries(this.stages)) {
          stages.push({
            value: key,
            text: label[this.language],
          })
        }
      } else {
        stages.push({
          value: 'final',
          text: this.stages.final[this.language],
        })
      }

      return stages
    },
  },

  methods: {
    goToView(routeName) {
      this.$router.push({ name: `manage-tournament`, params: { key: this.tournamentKey, view: routeName } })
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "dashboard": "Dashboard",
    "activity": "Atividade",
    "people": "Pessoas",
    "queues": "Filas",
    "notifications": "Notificações",
    "settings": "Configurações",
    "metadata": "Metadados",
    "teams": "Equipas",
    "reports": "Mapas"
  },
  "en": {
    "dashboard": "Dashboard",
    "activity": "Activity",
    "people": "People",
    "queues": "Queues",
    "notifications": "Notifications",
    "settings": "Settings",
    "metadata": "Metadata",
    "teams": "Equipas",
    "reports": "Reports"
  }
}
</i18n>
