<template>
  <div v-if="tmpStage">
    <div class="mt-4">
      <fw-label>Tipo</fw-label>
      <b-select v-model="tmpStage.type" placeholder="Tipo" expanded>
        <option v-for="(option, key) in typesList" :key="key" :value="key">
          {{ option[language] }}
        </option>
      </b-select>

      <fw-tip v-if="$v.tmpStage.type.$error" error> Insira a edição dos novos torneios</fw-tip>
    </div>

    <div class="mt-4">
      <fw-label>Número máximo de equipas por torneio</fw-label>
      <div class="flex flex-col gap-3">
        <NumberInput v-model="tmpStage.max_teams" :placeholder="'Número de equipas'" :maxlength="20"></NumberInput>
      </div>
      <fw-tip v-if="$v.tmpStage.max_teams.$error" error> Insira o número máximo de equipas</fw-tip>
    </div>

    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="$emit('close')">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disabled="saving" :loadind="saving" class="w-28" @click.native="saveStage">
        {{ 'Guardar' }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import { GENDERS, TOURNAMENT_TYPES, TOURNAMENT_STAGES } from '@/utils/index.js'
import { required, numeric, minLength } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'

export default {
  components: {
    NumberInput,
  },

  props: {
    stage: {
      type: Object,
      default: () => {},
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    saving: {
      type: Boolean,
      default: false,
    },

    hasOnlyFinalStage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      minSignUpDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      minDate: Dates.now()
        .add(1, 'day')
        .toDate(),
      stages: TOURNAMENT_STAGES,
      gendersList: GENDERS,
      typesList: TOURNAMENT_TYPES,
      tmpStage: null,
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },
  },

  mounted() {
    this.tmpStage = JSON.parse(JSON.stringify(this.stage))
    console.log('this.tmpStage :>> ', this.tmpStage)
  },

  validations() {
    return {
      tmpStage: {
        stage: { required, min: minLength(1) },
        type: { required, min: minLength(1) },
        max_teams: { numeric },
      },
    }
  },

  methods: {
    saveStage() {
      this.$v.$touch()
      if (this.$v.tmpStage.$invalid) return

      this.$emit('save', this.tmpStage)
      console.log('save me :>> ', this.tmpStage)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notDefined": "Não definido"
  },
  "en": {
    "notDefined": "Not defined"
  }
}
</i18n>
