var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"group text-sm flex-shrink-0 rounded inline-flex gap-2 p-2 items-center transition-colors duration-150 font-semibold",class:{
    'text-primary': _vm.active || (_vm.checkActualRoute && _vm.$router.currentRoute.path == _vm.to),
    'opacity-60 cursor-not-allowed': _vm.disabled,
    'hover:text-primary': !_vm.disabled,
    'w-full': _vm.fullWidth,
  },attrs:{"disabled":_vm.disabled},on:{"click":_vm.goToOrRun}},[(!_vm.noIcon)?_c('span',{staticClass:"rounded-xl text-gray-500 opacity-50 w-7 min-w-max flex-shrink-0",class:{
      'text-primary': _vm.active,
      'group-hover:text-primary group-hover:opacity-90': !_vm.disabled,
    }},[_vm._t("svg",function(){return [_c('fw-icon-' + _vm.icon,{tag:"component",staticClass:"w-6 h-6"})]})],2):_vm._e(),_c('span',{staticClass:"group-opacity-100 text-left",class:{ 'text-sm': _vm.size === 'sm' }},[_c('span',{staticClass:"line-clamp-1 whitespace-nowrap"},[_vm._v(_vm._s(_vm.label))])]),(_vm.counter)?_c('span',{staticClass:"text-xs px-1.5 py-0.5 rounded-full bg-primary text-white flex-shrink-0"},[_vm._v(_vm._s(_vm.counter))]):_vm._e(),(_vm.$slots.suffix)?_c('span',{staticClass:"flex-shrink-0"},[_vm._t("suffix")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }